import { Helmet } from "react-helmet";
import React, { Component } from 'react'
export const Helm = (props) => {
    return (
        <Helmet>
            <title>{props.title}</title>
            <meta name="title" content={props.title} />
            <meta name="description" content={props.desc} />

            <link rel="icon" href={props.logo}></link>

            <meta property="og:type" content="website" />
            <meta property="og:url" content={props.url ? props.url : ''} />
            <meta property='og:image' itemprop="image" content={props.img ? props.img : ''} />
            <meta name="color-scheme" content="light" />

            <meta property="og:title" content={props.title} />
            <meta property="og:description" content={props.desc} />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={props.url ? props.url : ''} />
            <meta property="twitter:title" content={props.title} />
            <meta property="twitter:description" content={props.desc} />
            <meta property="twitter:image" content={props.img ? props.img : ''} />
            <meta property="og:site_name" content="Undanganku" />
            <meta property="og:updated_time" content="1440432930" />
            <meta http-equiv="cache-control" content="max-age=0" />
            <meta http-equiv="cache-control" content="no-cache" />
            <meta http-equiv="expires" content="0" />
            <meta http-equiv="expires" content="Tue, 01 Jan 1980 1:00:00 GMT" />
            <meta http-equiv="pragma" content="no-cache" />

            <link rel="canonical" href={props.url ? props.url : ''} />
            {
                props.children?props.children:''
            }
        </Helmet>
    )
}
