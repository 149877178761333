import React, { Component } from 'react'
import { Table } from 'reactstrap';
import { Helm } from '../components/header'
import Container from 'react-bootstrap/Container'
import Header, { Item, Mempelai, Divider, Slider } from '../components/main'
import { Helmet } from "react-helmet";
import { Form, Row, Col, Button } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import '../style/gold1.css'
import slide1 from '../assets/img/gold1/slide1.png'
import slide2 from '../assets/img/gold1/slide2.png'
import slide3 from '../assets/img/gold1/slide3.png'
import path from '../assets/img/gold1/path.svg'
import music from '../assets/music/music1.aac'
import logoig from '../assets/img/logoig.svg'
import path3 from '../assets/img/gold1/path3.svg'
import groom from '../assets/img/gold1/rhobby.png'
import bride from '../assets/img/gold1/rovita.png'
import path2 from '../assets/img/gold1/path2.svg'
import bunga6 from '../assets/img/bunga6.png'
import logo from '../assets/img/logo.ico'
import AOS from 'aos';
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';


export default class Halo extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()

    this.state = {
      acara: [],
      days: '00',
      minutes: '00',
      hours: '00',
      seconds: '00',
      hide:true,
      slide:[slide1,slide2,slide3]
    }
  }

  componentDidMount() {
    AOS.init({
      // initialise with other settings
      duration: 1000
    });
    let acara = [[
      'Akad Nikah',
      '10 oktober 2020',
      'Masjid Roudotul Muchlisin',
      '08:00 - 10:00 WIB'
    ],
    [
      'Resepsi',
      '10 oktober 2020',
      'Ijen View Hotel and Lounge',
      '17:00 - 21:00 WIB'
    ]
    ]
    this.setState({ acara: acara })
    var countDownDate = new Date("10/10/2021").getTime();

    // Update the count down every 1 second
    var x = setInterval(() => {

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.setState({
        days: days, hours: hours, minutes: minutes, seconds: seconds
      })
    }, 1000);

  }
  play = () => {
    AOS.refresh()
    var snd = new Audio(music);
    snd.type = 'audio/aac';
    snd.play();
    
    this.setState({hide:false})
    setTimeout(()=>{
      var elmnt = document.getElementById('sectiongold14');
      elmnt.scrollIntoView();
    },1000)
  }
  googleCalendar = () => {
    window.open(`https://calendar.google.com/calendar/r/eventedit?
text=Event%20Title
&details=Pernikahan%20Willy%20dan%20Jessica
&dates=20201010T100000/20201010T113000
&ctz=Indonesia%2FEast_Java&title=Pernikahan%20Willy%20dan%20Jessica
    `, '_blank')
  }

  render() {
    let { acara, days, hours, minutes, seconds,hide,slide } = this.state

    return (
      <>
        <Helm
          title='Undanganku - Gold 1'
          desc="Undangan berbasis online harga mulai 100 ribuan, responsive, interaktif, dan modern"
          logo={logo}
        />
        <div id='gold1'>
          <div className='w-100' style={{
            overflow: 'hidden', maxWidth: '100vw'
          }}>

            <div className="jumbotron jumbotron-fluid mb-0 w-100" id='sectiongold11' style={{
              overflow: 'hidden', maxWidth: '100vw'
            }}>
              <div className={`path w-100 ${hide?'hide':'show'}`}>
                <img src={path} className='img-fluid w-100' />
              </div>

              <div className="container" >

                <div className='position' data-aos="flip-left" data-aos-duration="1000" >
                  <Item><h1>You Are Invited!</h1>
                  </Item>
                  <Item><h2>The Wedding of</h2>
                  </Item>
                </div>
                <div
                  className='mt-3 nama'
                  data-aos="zoom-in" data-aos-duration="1000"
                >
                  <Item>
                    Rhobby
                      </Item>
                  <Item>
                    &
            </Item>
                  <Item>
                    Rovita
            </Item>
                </div>
                <div className='tombol'>
                  <Item>
                    <button
                      onClick={() => {
                        this.play()
                      }}
                      style={{
                      }}
                    >Open Invitation</button>
                  </Item>
                </div>
              </div>
            </div>
            <div className={hide?'d-none':'d-block'}>
            <Container id='sectiongold14' className='pb-md-5' ref={this.myRef}>
              <div className='path w-100' >
                <img src={path3} className='img-fluid w-100' />
              </div>
              <div className='pt-5 pb-5'>

                <Item>
                  <Col xs={4} lg={1}>
                    <img src={path2} className='img-fluid w-100' data-aos="fade-left" data-aos-duration="1000" />
                  </Col>
                </Item>
                <Item>
                  <p className='text-center col-md-8 quotes pt-3 pb-md-5' data-aos="fade-left"
                    data-aos-duration="1000">
                    “And of His signs is that He created for you from yourselves mates that youmay
                    find tranquility in them; and He placed between you affection and mercy.Indeed
                  in that are signs for a people who give thought.” <br /><br />(Ar-Rum: 21)
              </p>
                </Item>

              </div>
            </Container>
            
            <Container id='sectiongold15'>
              <Item>
              <Col xs={12} md={8}>
              <div className='opening' data-aos="zoom-in">
                Maha Suci Allah yang telah menciptakan makhluk-Nya secara Berpasang-pasangan.
                Ya Allah perkenankan kami merangkai kasih sayang yang kau ciptakan diantara Putra Putri kami.
                </div>
              <Item>
                <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                  <div className='kotak mr-lg-2'>
                    <Item>
                      <h1>
                        The Groom
                </h1>
                    </Item>
                    <Item>
                      <Col xs={6}>
                        <img src={groom} className='img-fluid w-100' />
                      </Col>
                    </Item>
                    <Item>
                      <h1>
                        Rhobby Novta
                        Naba
            </h1>
                    </Item>
                    <Item>
                      <p className='text-center'>
                        The Son of<br />
                        Alm. Bpk. Subagiyo<br />
dan<br />
Ibu Suharnanik<br />
                      </p>
                    </Item>
                    <Item>
                      <Col xs={3}>
                        <img src={logoig} className='img-fluid w-100' />
                      </Col>
                    </Item>
                  </div>
                </div>
                <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                  <div className='kotak mr-lg-2'>
                    <Item>
                      <h1>
                        The Bride
            </h1>
                    </Item>
                    <Item>
                      <Col xs={6}>
                        <img src={bride} className='img-fluid w-100' />
                      </Col>
                    </Item>
                    <Item>
                      <h1 >
                        Rovita Meilina Puja Syaksena
                      </h1>
                    </Item>
                    <Item>
                      <p className='text-center'>
                        The Daughter of<br />
                        H.Marjulis., S.E.Msi
                        <br />
dan
<br />
Hj. Daesy Hariyani
            </p>
                    </Item>
                    <Item>
                      <Col xs={3}>
                        <img src={logoig} className='img-fluid w-100' />
                      </Col>
                    </Item>
                  </div>
                </div>
              </Item>
              </Col>
              </Item>
            </Container>
            
            <Container id='sectiongold17'>
              <div className='pt-3 ml-3 mr-3'>
                <Item>
                  <Col xs={4} lg={2}>
                    <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                  </Col>
                </Item>
                <Item>                
                <Col xs={12} md={6} className='katok'>
                
                {
                  acara.map((val, i) => {
                    return (
                      <div data-aos={`fade-${i % 2 == 0 ? 'left' : 'right'}`} data-aos-duration="1000">
                        <Item>
                          <h1>
                            {val[0]}
                          </h1>
                        </Item>
                        <Item>
                          <p className='bold italic'>
                            {val[1]}
                          </p>
                        </Item>
                        <Item>
                          <p className='italic'>
                            {val[2]}
                          </p>
                        </Item>
                        <Item>
                          <p className='mt-2'>
                            {val[3]}
                          </p>
                        </Item>
                      </div>
                    )
                  })
                }
                </Col>
                </Item>
                <Item>
                  <div data-aos="zoom-in" data-aos-duration="1000" className='col-6 col-lg-4 mt-3 button text-center'>
                    Get Direction
                </div>
                </Item>
                <Item>
                  <div data-aos="zoom-in" data-aos-duration="1000" className='col-6 col-lg-4 mt-3 button text-center smaller' onClick={() => {
                    this.googleCalendar()
                  }}>
                    Add to Google Calendar
                </div>
                </Item>
                <Item>
                  <div className='col-8 pt-3'>
                    <p data-aos="zoom-in" data-aos-duration="1000" className='italic red text-center'> Diberitahukan kepada setiap tamu undangan
                    untuk mengikuti protocol kesehatan
dalam menghadiri acara Resepsi</p>
                  </div>
                </Item>
                <Item>
                  <div data-aos="fade-left" data-aos-duration="1000" className='col-10 col-lg-8 kotak'>
                    <Item>
                      <div className='item'>
                        <Item>
                          <div>
                            {days}
                          </div>
                        </Item>
                        <Item>
                          <span>
                            Days
                      </span>
                        </Item>
                      </div>
                      <div className='dot'>:</div>
                      <div className='item'>
                        <Item>
                          <div>
                            {hours}
                          </div>
                        </Item>
                        <Item>
                          <span>
                            Hours
                      </span>
                        </Item>
                      </div>
                      <div className='dot'>:</div>
                      <div className='item'>
                        <Item>
                          <div >
                            {minutes}
                          </div>
                        </Item>
                        <Item>
                          <span>
                            Mins
                      </span>
                        </Item>
                      </div>
                      <div className='dot' >:</div>
                      <div className='item'>
                        <Item>
                          <div>
                            {seconds}
                          </div>
                        </Item>
                        <Item>
                          <span>
                            Secs
                      </span>
                        </Item>
                      </div>
                    </Item>

                  </div>
                </Item>
              </div></Container>
            <Container className='mt-3' data-aos="fade-right" data-aos-duration="1000">
              <Slider slide={slide}/>
            </Container>
            <Container id='sectiongold18' >
              <div className='pt-3 mt-4 mt-lg-5'>
                <Item>
                  <Col xs={4} lg={2}>
                    <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                  </Col>
                </Item>
                <Item>
                  <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="fade-left">
                    <Item>
                      <h1>
                        Send Your Wishes
                    </h1>
                    </Item>
                    <Item>
                      <form className="col-12 w-100 ">
                        <input type='text' className="col-12 w-100 text-center" placeholder="Nama" />
                        <input type='text' className="col-12 w-100 text-center" placeholder="Alamat" />
                        <input type='text-area' className="col-12 w-100 text-center bigger" placeholder="Pesan" />
                        <Item>
                          <input id="option" type="radio" name="field" value="option" defaultChecked />
                          <label for="option"><span><span></span></span>Hadir</label>
                          <input id="option" type="radio" name="field" value="option" />
                          <label for="option"><span><span></span></span>Tidak Hadir</label>
                        </Item>
                        <Item>
                          <div  className='btn col-6 button'> Kirim </div>
                        </Item>
                      </form>
                    </Item>
                  </div>
                </Item>
                <h2 data-aos="fade-right">Rhobby & Rovita</h2>
              </div>
            </Container>
            <Container id='sectiongold110' >
              <div className='path w-100 '>
                <img src={path3} className='img-fluid w-100 ' />
              </div>
            </Container>
            <Container className='text-center pb-5' id='sectiongold19'>
              <Item>
                <h1 data-aos="zoom-in" data-aos-duration="1000">
                  Possible Wedding
              </h1>
              </Item>
              <Item>
                <h2 data-aos="zoom-in" data-aos-duration="1000">
                  Digital Invitation
              </h2>
              </Item>
              <Item>
                <Col md={8}>
                  <Item>
                <div className='col-3 col-lg-1'>
                  <img src={logoig} className='img-fluid w-100'
                  />
                </div>
                </Item>
                </Col>
              </Item>
            </Container>
            </div>
          </div>
        </div>
      </>
    )
  }
}